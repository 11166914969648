<template>
  <v-col lg="6">
    <v-card class="mt-5">
      <v-card-title>Register</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="register">
          <v-text-field
            id="username-input"
            v-model="form.projectName"
            @keyup="filterProjectName"
            hint="No spaces or special characters."
            type="text"
            label="Display Name"
            placeholder="Enter a username"
          ></v-text-field>

          <validation :error="this.getErrors" error-key="username" />

          <v-text-field
            id="email-input"
            v-model="form.userName"
            type="text"
            label="Email"
            placeholder="Enter email"
          ></v-text-field>

          <validation :error="this.getErrors" error-key="email" />

          <v-text-field
            id="password-input"
            v-model="form.password"
            type="password"
            label="Password"
            placeholder="Enter password"
          ></v-text-field>

          <validation :error="this.getErrors" error-key="password" />
        </v-form>
      </v-card-text>
      
        <v-btn color="success" :loading="this.getLoading" @click="register" block>
          Resister</v-btn
        >
        <v-btn color="primary"  text to="/login" block class="mt-3">
          Login</v-btn
        >
     
    </v-card>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Register",
  data() {
    return {
      form: {
        projectName: "",
        password: "",
        userName: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getErrors", "getLoading"]),
  },
  methods: {
    filterProjectName(){
      this.form.projectName = this.form.projectName.replace(/[^a-zA-Z ]/g, "").replace(/\s+/g, '');
    },
    register() {
      this.$store.dispatch("register", this.form);
    },
  },
};
</script>
